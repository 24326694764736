export const cartographyConfig = {
  DEFAULT_OUTPUT_DPI: 300,
  IMAGE_OUTPUT_WIDTH_PX: 1920,
  IMAGE_OUTPUT_HEIGHT_PX: 1080,

  mapbox: {
    accessToken: 'pk.eyJ1Ijoic2Vhbml4IiwiYSI6ImNrM3F3aDQ1dzA2OGQzZHF1YTB3YnZrc2cifQ.EByai3nTlJcjT0CMkG_0aw',
    mapStyle: 'mapbox://styles/seanix/ck5xpb9wg0xbh1jqxl28fispt',
  },

  cartography: {
    clusterRadius: 50,
    spreadDistanceFactor: 55,
    regionalZoom: 8,
    symbols: {
      circleColor: '#373D3F',
      circleRadius: 16,
      fontSize: 20,
      textColor: '#FFFFFF',
    },
  },
};
