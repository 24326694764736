import { Injectable } from '@angular/core';
import { Museum } from '@app/models';
import { ApiService } from '../api/api.service';
import { IMuseumQueryParams } from '../../interfaces/IMuseumQueryParams';
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse';

@Injectable({
  providedIn: 'root',
})
export class MuseumsService {
  constructor(private api: ApiService) {}

  getMuseums(queryParams?: IMuseumQueryParams) {
    return this.api.get<IPaginatedResponse<Museum>>('museums', queryParams);
  }
}
