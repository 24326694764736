import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { State } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  constructor(private api: ApiService) {}

  getAllStates() {
    return this.api.get<State[]>('state');
  }
}
