import { Component, OnInit } from '@angular/core';
import { Museum, State } from '@app/models';
import { MuseumsService, StatesService } from '@app/services';
import { IMuseumQueryParams, IMuseumMarker } from '@app/interfaces';
import mapboxgl, { GeoJSONSource, MapboxGeoJSONFeature, AnySourceImpl, LngLatLike } from 'mapbox-gl';
import Supercluster, { AnyProps, ClusterProperties, PointFeature } from 'supercluster';
import { Point, Feature, FeatureCollection, BBox } from 'geojson';
import { saveAs } from 'file-saver';
import { GeoJSONTypes, GeoJSONSourceTypes } from '@app/enums';
import { cartographyConfig } from '@app/configs';
import { MatSelectChange, MatOption } from '@angular/material';

const defaultCoordinates = {
  latitude: 64.7293,
  longitude: 18.0686,
}; // Sweden coordinates

@Component({
  selector: 'app-museum-selection',
  templateUrl: './museum-selection.component.html',
  styleUrls: ['./museum-selection.component.scss'],
})
export class MuseumSelectionComponent implements OnInit {
  museumsToPlot!: Museum[];
  states!: State[];
  filterParams!: IMuseumQueryParams;
  map!: mapboxgl.Map;
  bounds!: mapboxgl.LngLatBounds;
  museumCircleLayer!: mapboxgl.Layer;
  museumSymbolLayer!: mapboxgl.Layer;
  museumLocations!: FeatureCollection<Point, IMuseumMarker>;
  isClusterSpreadLocked!: boolean;
  lastZoom!: number;
  isMapReady!: boolean;
  clusterIndex!: Supercluster;
  selectedRegionName!: string;
  outputMapWidth!: number;
  outputMapHeight!: number;
  museumGeoJSONSourceName!: string;

  constructor(private museumService: MuseumsService, private stateService: StatesService) {}

  initMap() {
    this.museumLocations = {
      type: GeoJSONTypes.FeatureCollection,
      features: [],
    };
    this.lastZoom = 0;
    this.isMapReady = false;
    this.clusterIndex = new Supercluster({ radius: cartographyConfig.cartography.clusterRadius });

    // https://stackoverflow.com/questions/44332290/mapbox-gl-typing-wont-allow-accesstoken-assignment
    (mapboxgl as typeof mapboxgl).accessToken = cartographyConfig.mapbox.accessToken;

    const { circleColor, circleRadius, fontSize, textColor } = cartographyConfig.cartography.symbols;

    this.museumCircleLayer = {
      id: 'circle-museums',
      type: 'circle',
      source: this.museumGeoJSONSourceName,
      paint: {
        'circle-radius': circleRadius,
        'circle-color': circleColor,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 1,
      },
    };

    this.museumSymbolLayer = {
      id: 'symbol-museums',
      type: 'symbol',
      source: this.museumGeoJSONSourceName,
      layout: {
        'text-field': ['get', 'number'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': fontSize,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': textColor,
      },
    };
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: cartographyConfig.mapbox.mapStyle,
      zoom: 5.3,
      minZoom: 5.3,
      center: [defaultCoordinates.longitude, defaultCoordinates.latitude],
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    const canvas = this.map.getCanvasContainer();

    this.map.on('load', () => {
      this.map.addSource(this.museumGeoJSONSourceName, { type: GeoJSONSourceTypes.GeoJSON, data: this.museumLocations });
      this.map.addLayer(this.museumCircleLayer);
      this.map.addLayer(this.museumSymbolLayer);
      this.lastZoom = this.map.getZoom();
      this.isMapReady = true;

      let canMove = false;
      let selectedFeature: Feature<Point, IMuseumMarker> | MapboxGeoJSONFeature;

      this.map.on('mouseenter', 'circle-museums', () => {
        canvas.style.cursor = 'move';
      });

      this.map.on('mouseleave', 'circle-museums', () => {
        canvas.style.cursor = 'grab';
      });

      this.map.on('mousedown', 'circle-museums', mouseDownEvent => {
        // Prevent the default map drag behavior.
        mouseDownEvent.preventDefault();
        canvas.style.cursor = 'grab';
        selectedFeature = this.map.queryRenderedFeatures(mouseDownEvent.point, {
          layers: ['circle-museums'],
        })[0];

        canMove = true;
      });

      this.map.on('mousemove', mouseMoveEvent => {
        if (canMove) {
          const newCoords = mouseMoveEvent.lngLat;
          const feature = this.museumLocations.features.find(
            f => selectedFeature.properties !== null && f.properties.number === selectedFeature.properties.number,
          );

          if (feature) {
            canvas.style.cursor = 'grabbing';
            feature.geometry.coordinates = [newCoords.lng, newCoords.lat];

            // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14877
            (this.map.getSource(this.museumGeoJSONSourceName) as mapboxgl.GeoJSONSource).setData(this.museumLocations);
          }
        }
      });

      this.map.on('mouseup', () => {
        canMove = false;
        canvas.style.cursor = 'grab';
      });
    });

    this.map.on('zoomend', () => {
      this.updateClusterSpread();
    });
  }

  ngOnInit() {
    this.states = [];
    this.museumsToPlot = [];
    this.filterParams = {};
    this.isClusterSpreadLocked = false;
    this.selectedRegionName = 'none';
    this.outputMapWidth = cartographyConfig.IMAGE_OUTPUT_WIDTH_PX;
    this.outputMapHeight = cartographyConfig.IMAGE_OUTPUT_HEIGHT_PX;
    this.museumGeoJSONSourceName = 'museums';

    this.stateService.getAllStates().then(res => (this.states = res));

    this.initMap();
    this.buildMap();
  }

  updateClusterSpread() {
    if (!this.isClusterSpreadLocked) {
      const actualZoom = this.map.getZoom();
      const currentZoomInt = Math.max(Math.floor(actualZoom), Math.ceil(this.map.getMinZoom()));

      if (this.lastZoom !== currentZoomInt) {
        this.map.setZoom(currentZoomInt);
        this.updateFeaturesOnMap();
        this.map.setZoom(actualZoom);
        this.lastZoom = currentZoomInt;
      }
    }
  }

  retrieveMuseums() {
    this.museumsToPlot = [];
    this.museumService.getMuseums(this.filterParams).then(res => {
      this.museumsToPlot = res.data;
      this.flyToSelectedMuseums();
    });
  }

  regionChanged(selectChange: MatSelectChange) {
    this.filterParams.state = selectChange.value;
    const selectedState = this.states.find(x => x.id === selectChange.value);

    if (selectedState) {
      this.selectedRegionName = selectedState.name;
      this.retrieveMuseums();
    }
  }

  flyToSelectedMuseums() {
    this.map.setZoom(cartographyConfig.cartography.regionalZoom);
    this.updateFeaturesOnMap();

    if (!this.bounds.isEmpty()) {
      this.map.setCenter(this.bounds.getCenter());
    }
  }

  toggleSpreadByZoomLock() {
    this.isClusterSpreadLocked = !this.isClusterSpreadLocked;
    this.updateClusterSpread();
  }

  updateFeaturesOnMap() {
    this.museumLocations.features = [];

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14877
    (this.map.getSource(this.museumGeoJSONSourceName) as GeoJSONSource).setData(this.museumLocations); // Clear existing features on the map

    this.bounds = new mapboxgl.LngLatBounds();
    this.museumsToPlot.forEach((museum, index) => {
      const cluster: Feature<Point, IMuseumMarker> = {
        type: GeoJSONTypes.Feature,
        geometry: {
          type: GeoJSONTypes.Point,
          coordinates: [museum.long, museum.lat],
        },
        properties: {
          number: index + 1,
        },
      };

      this.bounds.extend(new mapboxgl.LngLat(museum.long, museum.lat));
      this.museumLocations.features.push(cluster);
    });

    if (!this.bounds.isEmpty()) {
      this.clusterIndex.load(this.museumLocations.features);

      // Flatten bound's arrays into one array
      const boundingBox = this.bounds.toArray().flat<BBox>() as BBox;
      this.map.dragRotate.disable();
      this.map.dragPan.disable();

      // Apply padding to bounding box
      const padding = 0.001;
      boundingBox[0] -= padding;
      boundingBox[1] -= padding;
      boundingBox[2] += padding;
      boundingBox[3] += padding;

      const { spreadDistanceFactor } = cartographyConfig.cartography;

      const featuresOnMap: (PointFeature<ClusterProperties> | PointFeature<AnyProps>)[] = this.clusterIndex.getClusters(
        boundingBox,
        Math.floor(this.map.getZoom()),
      );

      featuresOnMap
        .filter(cluster => cluster.properties.cluster === true)
        .forEach(cluster => {
          const featuresInCluster = this.clusterIndex.getLeaves(cluster.properties.cluster_id, Infinity);
          const clusterOriginCoordinates: [number, number] = [cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]];
          this.spreadClusterFeatures(featuresInCluster, spreadDistanceFactor, clusterOriginCoordinates);
        });

      // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14877
      (this.map.getSource(this.museumGeoJSONSourceName) as GeoJSONSource).setData(this.museumLocations);

      this.map.dragRotate.enable();
      this.map.dragPan.enable();
    }
  }

  spreadClusterFeatures(featureCluster: Feature<Point>[], distance: number, clusterOrigin: [number, number]) {
    const origin = this.map.project(clusterOrigin);

    const goldenRatio = (Math.sqrt(5) + 1) / 2;
    const lengthRatio = Math.sqrt(featureCluster.length - 1 / 2);

    featureCluster.forEach((cluster, index) => {
      const r = (Math.sqrt(index / 2) * (7 / this.map.getZoom() + 0.5) * distance) / lengthRatio;
      const theta = (index + 1) * 360 * goldenRatio;
      const point = new mapboxgl.Point(origin.x, origin.y);
      point.x += r * Math.cos(theta);
      point.y += r * Math.sin(theta);
      const newGeoCoordinates = this.map.unproject(point).toArray();
      cluster.geometry.coordinates = newGeoCoordinates;
    });
  }

  printMap() {
    const actualPixelRatio = window.devicePixelRatio;
    Object.defineProperty(window, 'devicePixelRatio', { get: () => cartographyConfig.DEFAULT_OUTPUT_DPI / 96 });

    const renderMap = new mapboxgl.Map({
      container: 'renderMapContainer',
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      style: cartographyConfig.mapbox.mapStyle,
      bearing: this.map.getBearing(),
      pitch: this.map.getPitch(),
      interactive: false,
      preserveDrawingBuffer: true,
      fadeDuration: 0,
      attributionControl: false,
    });

    renderMap.on('load', () => {
      renderMap.addSource(this.museumGeoJSONSourceName, { type: GeoJSONSourceTypes.GeoJSON, data: this.museumLocations });
      renderMap.addLayer(this.museumCircleLayer);
      renderMap.addLayer(this.museumSymbolLayer);
    });

    renderMap.once('idle', () => {
      renderMap.getCanvas().toBlob(blob => {
        saveAs(blob || '', this.selectedRegionName + '_map.png');
      }, 'image/png');

      renderMap.remove();
      Object.defineProperty(window, 'devicePixelRatio', { get: () => actualPixelRatio });
    });
  }
}
