import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSelectModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { MuseumCartographyRoutingModule } from './museum-cartography-routing.module';
import { MuseumSelectionComponent } from './museum-selection/museum-selection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [MuseumSelectionComponent],
  imports: [
    CommonModule,
    MuseumCartographyRoutingModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
  ],
})
export class MuseumCartographyModule {}
