import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = `${environment.SERVER_API_URL}`;

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: no-any
  get$<T>(path: string, queryParams?: any): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${path}`, {
      params: queryParams,
    });
  }

  // tslint:disable-next-line: no-any
  get<T>(path: string, queryParams?: any): Promise<T> {
    return this.get$<T>(path, queryParams).toPromise();
  }
}
